import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Starswap V2, the second iteration of the Starswap protocol, has been `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f#code"
      }}>{`deployed to the Ethereum mainnet`}</a>{`!`}</p>
    <p>{`An `}<a parentName="p" {...{
        "href": "https://starswap.xyz/audit.html"
      }}>{`audit report and formal verification`}</a>{` has already been released and the `}<a parentName="p" {...{
        "href": "/Starswap/status/1250474233131495424"
      }}>{`Starswap V2 Bug Bounty`}</a>{` has been running for over a month.`}</p>
    <p>{`Developers can begin building on Starswap V2 immediately! Initial `}<a parentName="p" {...{
        "href": "http://starswap.xyz/docs/v2"
      }}>{`docs`}</a>{` and `}<a parentName="p" {...{
        "href": "http://github.com/Starswap/Starswap-v2-periphery/tree/master/contracts/examples"
      }}>{`example projects`}</a>{` are already available.`}</p>
    <p>{`Today, the following open source projects are also being released:`}</p>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://migrate.app.starswap.xyz/"
        }}>{`migration portal`}</a>{` for moving liquidity from Starswap V1 to Starswap V2`}</li>
      <li parentName="ul">{`An updated `}<a parentName="li" {...{
          "href": "https://app.starswap.xyz/"
        }}>{`interface`}</a>{` for swapping and liquidity provision on Starswap V2`}</li>
      <li parentName="ul">{`An updated `}<a parentName="li" {...{
          "href": "https://Starswap.info/"
        }}>{`info site`}</a>{` for Starswap V2 analytics`}</li>
    </ul>
    <p>{`Starswap V2 has many new features and technical improvements compared with Starswap V1 including:`}</p>
    <ul>
      <li parentName="ul">{`ERC20 / ERC20 Pairs`}</li>
      <li parentName="ul">{`Price Oracles`}</li>
      <li parentName="ul">{`Flash Swaps`}</li>
      <li parentName="ul">{`And much more!`}</li>
    </ul>
    <p>{`For full details on the benefits of Starswap V2 for liquidity providers and traders, please read the Starswap V2 `}<a parentName="p" {...{
        "href": "https://starswap.xyz/blog/Starswap-v2"
      }}>{`announcement blog post`}</a>{`. For more information on the launch please read below.`}</p>
    <h2 {...{
      "id": "liquidity-migration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidity-migration",
        "aria-label": "liquidity migration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Migration`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://migrate.app.starswap.xyz/"
      }}>{`migration portal`}</a>{` makes the process of withdrawing liquidity from Starswap V1 and depositing it into Starswap V2 fast and simple.`}</p>
    <p>{`This portal is only for Starswap V1 liquidity providers. If you are not a Starswap V1 liquidity provider but wish to use Starswap V2, you can do so from the `}<a parentName="p" {...{
        "href": "https://app.starswap.xyz/"
      }}>{`updated interface`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e55833300837409f3f56c3748c08069b/f349c/migrate.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQ4y4VTW3KjMBDMRbYSBHohxEPiIR7GNnaSyh5g//c+e4q9aG8hjO2sE/uja0RpptU9MzyRSCKYEQqQUwwIRxBen8XyHV1ATljrVjyRq4T5kqctYruHyHofZ8hiBJUGQcjPeSHTIFQ9ICTcE2j3AVUdkdTvHrr5gConxGbn4/zANckdhRwi35xUbaHKA2S+8QTcTBDluwcvdiA09m343/aNZZZUkHkPmfUQWQeZD5DFBiIfIYoteDZAZAOYKm/sXgjDi8JI96D5dMIeNB0RSet7SGMLGi8x5NmnQX4iPDc6kuDKQCgDmdjlnBgImV4mTpbBREwh9La/GMpKSHmCqu5QNT3aboStWrhu9FiLZzdcpkjS0ud/PeUr6SGVvnjGqiJiMbjUYCI5t8arjeR9y94KT8HEXLxEyrVXskSNiCUIWQrC0seWSRTD1RbdcMBmfEM3HOG6CW1/gHMtrG2g4gzm1x9Uv/8iFAYBYd8Tzi9muUVWNMiNO8fCtpBxBj6rlsavUGwnEKYfDyW3DqZ0qJsBxjo/oNoN/v45YCCyRiAsAmpB6B3ClXRWqLSFnFdnXpu4gFQFEl1CaAN+UipciVAoBORbwmUl3o57DOOr71vbTajdFm0/oXE7JKWDymooVUH/3CHSGYKAf702q/QfL9xbeyH8Bj5n/aueuVfnV+dqbf4BNWzdQnJuRxEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "migrate",
            "title": "migrate",
            "src": "/static/e55833300837409f3f56c3748c08069b/c1b63/migrate.png",
            "srcSet": ["/static/e55833300837409f3f56c3748c08069b/5a46d/migrate.png 300w", "/static/e55833300837409f3f56c3748c08069b/0a47e/migrate.png 600w", "/static/e55833300837409f3f56c3748c08069b/c1b63/migrate.png 1200w", "/static/e55833300837409f3f56c3748c08069b/d61c2/migrate.png 1800w", "/static/e55833300837409f3f56c3748c08069b/f349c/migrate.png 2002w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "updated-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#updated-interface",
        "aria-label": "updated interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Updated Interface`}</h2>
    <p>{`The open source `}<a parentName="p" {...{
        "href": "https://app.starswap.xyz/"
      }}>{`interface`}</a>{` has been updated to work with Starswap V2!`}</p>
    <p>{`It now supports creating and providing liquidity in ERC20 / ERC20 pairs, such as DAI/USDC.`}</p>
    <p>{`Swapping logic has been updated to properly route between ERC20 / ERC20 pairs.`}</p>
    <p>{`While Starswap V2 uses WETH, this is abstracted in the interface and ETH can be used directly.`}</p>
    <h4 {...{
      "id": "swapping",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#swapping",
        "aria-label": "swapping permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Swapping`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/346ad836ce955e49f05d5b3ba4751efa/da893/swap.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+klEQVQ4y4WTzXLTMBSF+yKl8U/8Iym25DSJ7cZ22qSldCjDFJiBFTuGDdB34CV4KVh02Zf5GMlp4gJDF3ekGel8Oude+6A6WbPsLii7l6jVR6ReokyLKlao6RnqeI063mzL7s+QpqNYbLi4ekfdXlI355T1hkW95kBmc2ReInWN1CdI06CKzomEbpHFClmc7sv0Z/2dxhlwWsvI5gNgvgfKoiOMM3IzJ0oygkgRJTleKAjjCcFYIU3bl9XkTwFN64RCGeI0ww8S/DBhHEsSkTO2Z9vWPAlUpkHoBpXNaLo1y3btAKN0TlG+YFF17pH/AzMLrP5yGCUTkjQjGEv8KMePNP5Y4IcpUjfbHv4TuHgM1A2xMPihYBSku1Vrg5mWRGmOyPs0PbDqTfXA2QBYI83SRU7SCYHn4Y9GBN6IZ4dHtLOEmZkQBJGLbO/2E656hgNOZjzu4xKRV0T6lKh8ize9xiuuias3yOY9svlAVJwjsqoH7uIusKw98MGljaEM/tV3jr7cc/jpJ+m3O+TtHUeff+F9vce7+YGwOuvSarb9GwAfYpe7CIlZIctXyPKacXFJaJ4TmgvC4pJYr7Zp6kewPfBPl3mN0hV6euJKZHPSydy5cs4GD9u92A1lMQQOXG6hdpK2XK/c57Qc/Gr9INx5fYOsXpNON/wGDgqeY7eq1qsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "swap",
            "title": "swap",
            "src": "/static/346ad836ce955e49f05d5b3ba4751efa/c1b63/swap.png",
            "srcSet": ["/static/346ad836ce955e49f05d5b3ba4751efa/5a46d/swap.png 300w", "/static/346ad836ce955e49f05d5b3ba4751efa/0a47e/swap.png 600w", "/static/346ad836ce955e49f05d5b3ba4751efa/c1b63/swap.png 1200w", "/static/346ad836ce955e49f05d5b3ba4751efa/d61c2/swap.png 1800w", "/static/346ad836ce955e49f05d5b3ba4751efa/da893/swap.png 2130w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "liquidity-provision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#liquidity-provision",
        "aria-label": "liquidity provision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Provision`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3798666ddcc556cbbece919d22f7900/5e703/pool.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQ4y33UyXLbRhCAYb1FqiIH5ADENguWAUCJJECQlGxFl2zlLJWc7ErlGfIOuaayVeUJclDZJ7/d78IQEknLyqEv4ODr7ukGz1TRosoOVa7RtkfbDbraousrTH2NqZ9jmkPo+tr9pqvd/qzt3bt7o+XsKTAt1oco+30U/fisQ90nthvUAzqA5QdgtUUWHf7EI/Yn+N6nCO+cUEzwzj/BpCEynBImOaa+cthxlQfQrsfqdsh8hbj+FfHyX/xv/iD57i/kD38Tffsnwct/EF//TlTdjh1tnwL7EzD44jeCV+9IX72h/OUt2c936Nd3TH+6Q/z4H9H8S7Tr6inwoeUdad5iZEpdGOoyRyUx3rNzhPcZwntGHExJpEVXV48GczKUoUpTb4lMy7wpuWx32GZFLAvETOKHCj/UBKEiVs046f5oKB8B3YTzFYmymNwidcksUvizFBEkTETM1A+JVO1A9X9rM4SptsTmEttsuLn5nMtl77CpH5OqnHq+IrMbYn3hkp+AhQNXnNxjtSHJlkhVsFht0FnlKhsiSjIWy56+bUh07QZyX8gpWHwAmgWmaLh6fst294IoVgg/YiJCSntB13VEqXVnj9sdrBE8VDlc8rA2figJZil+YhHpHF9eIGJLEEp3BZGsxul2D9WN4JJDlS3arpHZAhGVeKpHFDd4ao2XLvHtLUF5gxfPT8ERG6wzlS84RnXZkQ7gsCZxTqQqwrQkTHNmskYkFdOZ3Lds10fYCpXfg/nygJbt/kuJDHGakRUNJq/J8orEzAkS6/Yw0Y1L/oAN7+eLAbzkEeqy7jMneUdy8RVy9T1p9WI8155WNmJ7MBvAj6FH0x8WuL52f1mPoQM2OO8BBH0eHNFpe2wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pool",
            "title": "pool",
            "src": "/static/e3798666ddcc556cbbece919d22f7900/c1b63/pool.png",
            "srcSet": ["/static/e3798666ddcc556cbbece919d22f7900/5a46d/pool.png 300w", "/static/e3798666ddcc556cbbece919d22f7900/0a47e/pool.png 600w", "/static/e3798666ddcc556cbbece919d22f7900/c1b63/pool.png 1200w", "/static/e3798666ddcc556cbbece919d22f7900/d61c2/pool.png 1800w", "/static/e3798666ddcc556cbbece919d22f7900/5e703/pool.png 1942w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "pool-creation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#pool-creation",
        "aria-label": "pool creation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pool Creation`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cdbd2679c49d777655d6f6cdf9d310a/c0566/create.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4klEQVQ4y42T3W7TMBTH9xwIGjfOl2PHbRKvqdIkS/oxBi+AJi4QD9EMEBIX8Fo80gTsatrNH8X5aNbC4OInx8fJL8dH55zx6AJCU0JEFUS87thAqIZtx6aNaZrzqn1ff3cBHhaas4OsHGS8WVUr5PGBXsqHn46EnfQgjCv4YQHXD+GwOagrYXszeCIGEzFcP4LlSk0Td/l5K+2TORGqDZhMQW0O0+aYEAuOF2CVV0izEpFagpgOptSD589guxJcl6j6szBQG9hihSTmKDMFy53BpB6mlGlJg2kxje1wnamWPS1MsVQB1kUC25s/koz5L2FTD3+e4bnh4tkLB8R0BwG1/UfC5ow6wb8yXMOTK3iMQUjZXtnydT2PoeMMoyeElGcozg28Ll0wFmBqTGAScoJjUVgOH2V3JNTNHa/B5BKGvMRk8RZk8Qbm8hokuYaRtGuz72NUpBBh0WU46kPeC5u2EQrk/XdMPz+A7G9BP/yE+nqHxbc7yC+/YNQ/QOpbkE/3oLsbCLnoenE0KfpBZ1jBn6Wgq3eglx9Bt3vQXQ3vqga7uoHzsgbd1qC7vZY58SuIMNfX5YMwHwk1FbhMwEUMHiiNLw70seZchNnJHHfCXG9ELx3mePsXupk+muHWk+M3FdDD7zetRpkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "create",
            "title": "create",
            "src": "/static/1cdbd2679c49d777655d6f6cdf9d310a/c1b63/create.png",
            "srcSet": ["/static/1cdbd2679c49d777655d6f6cdf9d310a/5a46d/create.png 300w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/0a47e/create.png 600w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/c1b63/create.png 1200w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/c0566/create.png 1544w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "info-site",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info-site",
        "aria-label": "info site permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info Site`}</h2>
    <p>{`Starswap V2 analytics are available at `}<a parentName="p" {...{
        "href": "http://Starswap.info/"
      }}>{`Starswap.info`}</a>{`, built on top of the open source `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v2-subgraph"
      }}>{`Starswap V2 subgraph`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/83f8197632f23250f154bd8ea63ef37f/a09be/info.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdtRQT//xAAXEAEAAwAAAAAAAAAAAAAAAAAQATFB/9oACAEBAAEFAqgw/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGBABAQADAAAAAAAAAAAAAAAAAQARITH/2gAIAQEAAT8hejGyOWB2sRf/2gAMAwEAAgADAAAAENAP/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQEQ/9oACAEDAQE/EGGf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBUWH/2gAIAQEAAT8QqzgGuQthVOmRWE4Y4S79lA+GQAUFB1P/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "info",
            "title": "info",
            "src": "/static/83f8197632f23250f154bd8ea63ef37f/e5166/info.jpg",
            "srcSet": ["/static/83f8197632f23250f154bd8ea63ef37f/f93b5/info.jpg 300w", "/static/83f8197632f23250f154bd8ea63ef37f/b4294/info.jpg 600w", "/static/83f8197632f23250f154bd8ea63ef37f/e5166/info.jpg 1200w", "/static/83f8197632f23250f154bd8ea63ef37f/d9c39/info.jpg 1800w", "/static/83f8197632f23250f154bd8ea63ef37f/df51d/info.jpg 2400w", "/static/83f8197632f23250f154bd8ea63ef37f/a09be/info.jpg 2772w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "what-will-happen-to-starswap-v1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-will-happen-to-starswap-v1",
        "aria-label": "what will happen to starswap v1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What will happen to Starswap V1`}</h2>
    <p>{`Starswap V1 is an automated, decentralized set of smart contracts. It will continue functioning for as long as Ethereum exists.`}</p>
    <h2 {...{
      "id": "all-other-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#all-other-questions",
        "aria-label": "all other questions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All other questions`}</h2>
    <p>{`For all questions, you can join and engage with the community in the `}<a parentName="p" {...{
        "href": "https://discord.gg/FCfyBSbCU5"
      }}>{`Starswap Discord`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      